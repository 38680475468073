module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Search Engine Unity","short_name":"seu","start_url":"/","lang":"en","display":"standalone","icon":"src/images/SY_favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"21fe853666be957d829442d63f7ed5e3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M7SVVV4","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/@awolf81/gatsby-theme-addthis/gatsby-browser.js'),
      options: {"plugins":[],"publicId":"ra-5fac56f8e2a99835"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
